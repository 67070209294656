<template>
    <div>
        <CSBreadcrumb />
        <div class="filter-panel">
            <CSSelect>
                <select v-model="type">
                    <option value="">事物类型不限</option>
                    <option v-for="(val, key) in relatedType" :key="key" :value="key">{{val}}</option>
                </select>
            </CSSelect>
            <button class="btn btn-primary" @click="queryList">查询</button>
        </div>

        <div class="result-panel">
            <CSTable :thead-top="filterHeight">
                <template v-slot:thead>
                    <tr>
                        <th>添加时间</th>
                        <th>事物类型</th>
                        <th>事物</th>
                        <th>详情</th>
                        <th>创建人</th>
                    </tr>
                </template>
                <template v-slot:tbody>
                    <template >
                        <tr v-for="item in list" :key="item.id">
                            <td>{{item.createTime}}</td>
                            <td>{{relatedType[item.type] || '-'}}</td>
                            <td>{{item.name}}</td>
                            <td>
                                <span class="allow-click" @click="lookRelatedTypeDetail(item)">查看</span>
                            </td>
                            <td>{{item.createUserName}}</td>
                        </tr>
                    </template>
                </template>
            </CSTable>
        </div>
        <CSDialog
          :dialogTitle="dialogTitle"
          :dialogVisible="dialogInfoVisible"
          dialogCancelBtnText="关闭"
          :dialogShowConfirmBtn="false"
          @onClose="dialogInfoVisible = false"
          dialogHeaderClass="alert-bg"
          dialogWidth="540px"
        >
          <div slot="dialog-content"
            style="padding: 20px 30px"
          >
            <div v-if="dialogTitle === '查看仪表详情'">
              <div class="row">
                <label class="row-label">仪表名称/编号</label>
                <span>{{detailsInfo.name || '-'}}</span>
              </div>
              <div class="row">
                <label class="row-label">仪表种类</label>
                <span>{{instrumentType[detailsInfo.type] || '-'}}</span>
              </div>
              <div class="row">
                <label class="row-label">最近抄表时间</label>
                <span>{{detailsInfo.lastReadingTime || '-'}}</span>
              </div>
              <div class="row">
                <label class="row-label">最新表底</label>
                <span>{{detailsInfo.lastBottom || '-'}}</span>
              </div>
            </div>

            <div v-else>
              <div class="row">
                <label class="row-label">物品名称</label>
                <span>{{detailsInfo.name}}</span>
              </div>
              <div class="row">
                <label class="row-label">品牌型号</label>
                <span>{{detailsInfo.model}}</span>
              </div>
              <div class="row">
                <label class="row-label">物品类型</label>
                <span>{{goodstype[detailsInfo.type]}}</span>
              </div>
              <div class="row">
                <label class="row-label">当前剩余数量</label>
                <span>{{detailsInfo.currentCount}}</span>
              </div>
            </div>
          </div>
        </CSDialog>
    </div>
</template>

<script>
import CSBreadcrumb from "@/components/common/CSBreadcrumb";
import CSSelect from "@/components/common/CSSelect";
import CSDialog from "@/components/common/CSDialog";
import {queryRelatedTransactionUrl, queryByNameUrl, querySingleUrl, queryMeterByIdUrl} from "@/requestUrl";
import CSTable from "@/components/common/CSTable";

export default {
    name: "LocationRelatedTransaction",
    components: {
        CSTable,
        CSBreadcrumb,
        CSSelect,
        CSDialog,
    },
    props: {
        id: Number,
    },
    data() {
        return {
            type: '',
            filterHeight: 0,
            // 仪表种类
            instrumentType: {
              1: '水表',
              2: '电表',
              3: '燃气表',
            },
            relatedType: {
                1: '巡检点',
                2: '物品',
                3: '仪表',
            },
            goodstype: {
                1: "设备零件",
                2: "消耗品",
                3: "工具",
                4: "工服",
                5: "其他",
            },
            list: [],
            // 物品与仪表详情共用变量
            detailsInfo: {},
            // 判断弹窗标题
            dialogTitle: "",
            // 弹窗显隐
            dialogInfoVisible: false,
        }
    },
    created() {
        this.queryList();
    },
    mounted() {
        this.filterHeight = document.querySelector('.filter-panel').offsetHeight;
        window.addEventListener('resize', () => {
            this.filterHeight = document.querySelector('.filter-panel').offsetHeight;
        })
    },
    methods: {
        // 获取关联事物列表
        queryList() {
            const {id, type} = this;
            this.$fly.get(queryRelatedTransactionUrl, {
                regionCode: this.$vc.getCurrentRegion().code,
                id,
                type,
            })
            .then(res => {
                if (res.code !== 0) {
                    this.list = [];
                    return ;
                }
                this.list = res.data;
            })
        },
        /**
         * 查看管理的类型详情
         * @param {Object} item 关联的事物
         * */
        lookRelatedTypeDetail(item) {
            switch (item.type) {
                case 1:
                    this.toInspectionDetails(this.id);
                    break;
                case 2:
                    this.getSingle(item.id);
                    break;
                case 3:
                    this.getMaterInfo(item.id);
                    break;
            }
        },
        // 获取仪表详情的(从抄表记录中拿的信息)
        async getMaterInfo(id) {
          let res = await this.$fly.get(queryMeterByIdUrl, { id });
          if (res.code != 0) {
            return;
          }
          this.dialogTitle = "查看仪表详情";
          this.dialogInfoVisible = true;
          this.detailsInfo = res.data;
        },
        // 获取物品详情
        async getSingle(id) {
          let res = await this.$fly.get(querySingleUrl, { id });
          if (res.code != 0) {
            return;
          }
          this.dialogTitle = "查看物品详情"
          this.dialogInfoVisible = true;
          this.detailsInfo = res.data;
        },
        // 巡检点去往三级页面
        toInspectionDetails() {
          this.$router.push({name: "patrolTask", params: {id: this.id}})
        }
    }
}
</script>

<style lang="stylus" scoped>
.row
  font-size 20px
  display flex
  .row-label
    width 230px
    text-align right
    margin-right 30px
</style>
